import React, { FC } from "react"
import {
  Center,
  Stack,
  Switcher,
  Text,
  PrimaryButton,
  Cluster,
  Box,
  Frame,
  Grid,
} from "../common"
import { Form, Input } from "antd"
import { css } from "@emotion/core"
import { MOBILE_NO_REGEX } from "../common/utils"
import { useContactUs } from "../components/useContactUs"
import { Link } from "gatsby"

import { ZHelmet } from "../components/ZHelmet"

import { Clock } from "../common/icons/ClockIcon"
import { Money } from "../common/icons/MoneyIcon"
import { Repayment } from "../common/icons/RepaymentIcon"
import { Bell } from "../common/icons/BellIcon"
import { NextIcon } from "../common/icons/NextIcon"
import { MobileLayout } from "../components/MobileLayout"
import { CityList } from "../components/CityList"

const Features = [
  {
    icon: <Clock />,
    title: "Apply Instantly",
    content: "Applying for an Instant Personal Loan is easy with ZinCashDownload App to fill details.",
  },
  {
    icon: <Money />,
    title: "High Disbursal Amount",
    content: "If details given by you fits our eligibility criteria, you can apply for a loan of up to 2 lacs.",
  },
  {
    icon: <Repayment />,
    title: "Easy Repayment",
    content:
      "Easy repayment options and competitive interest rates helps you repay your loans within a period of 36 months.",
  },
  {
    icon: <Bell />,
    title: "Easy Documentation",
    content:
      "We assist you with your document uploads to avail quick and easy personal loans that too hassle-free.",
  },
]


const Easy_Steps = [
  {
    title: 'Sign Up your accounts',
    content: [`You have to fill minimal details about you and your professoin to create an account.`],
  },
  {
    title: 'Fill Form Details',
    content: [`Filling form is very easy just keep typing the details as guided by App`],
  },
  {
    title: 'Get Instant Personal Loan',
    content: [`Once you finished you may get an instant personal loan as per your eligibility.`],
  },

]

const data = [
  {
    title: 'Application at your convenience',
    content: [`You have to fill minimal details about you and your professoin to create an account.`],
  },
  {
    title: 'Round the clock acceptance 24x7',
    content: [`Filling form is very easy just keep typing the details as guided by App`],
  },
  {
    title: 'Verification and Sanction Process',
    content: [`Once you finished you may get an instant personal loan as per your eligibility.`],
  },
  {
    title: 'Quick Approval & Disbursal',
    content: [`Once you finished you may get an instant personal loan as per your eligibility.`],
  },

]

const befor_Apply = [
  {
    title: 'Copy of PAN',
  },
  {
    title: 'Aadhar / Driving Licence / Passport / Voter ID (Any one)',
  },
  {
    title: 'Last 4 Months Salary Account s Bank Statement. Please that your statement should be bank generated and in PDF format only.',
  },

]


export default function Zincash() {
  return (
    <MobileLayout>
      <ZHelmet
        title="Instant Personal Loan App | Download Now | ZinCash Loan App"
        description="Apply now to get instant personal loan upto 2 lakh from ZinCash urgent loan app by Zavron Finserv download now best loan App for emergency loans"
        keywords="Personal Loan App, Instant Personal Loan App, Mobile Loan App, Quick Loan App, ZinCash Loan App, Download Personal Loan App, Lending App, Get Loan App, Best Loan App"
      />
      <Stack space="var(--s5)">
        <Landing />
        <WhyUs />
        <Products />
        < Textdata />
        <Products1 />
        <BeforApply />
        <CityList />

      </Stack>
    </MobileLayout>
  )
}

const Landing: FC = props => (

  <Box  css={css`
  @media screen and (min-width: 900px) {
    padding-top: var(--s2);

  }
`}> 
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>

        <Stack style={{ flexGrow: 2 }} space="var(--s0)" justify="center"
          css={css`
      @media screen and (min-width: 900px) {
        padding-right: var(--s5);

      }
    `}
        >
          <Stack
          space="var(--s0)"
          justify="center"
          css={css`
            @media screen and (min-width: 900px) {
              padding-right: var(--s5);

            }
          `}
        >
          {/* <Stack space="var(--s-4)" style={{ paddingTop: 'var(--s2)' }}> */}
            <Text as="h1" variant="h1">
              A Step Closer To Your Dreams
          </Text>
            <Text variant="h5" color="light">
              Introducing ZinCash an AI based app which provides the power to
              you. All new way to get an Instant Personal Loan through App on easy
              EMIs. It also enables immediate sanction and real-time disbursement
              of loans.

           </Text>
            <Text variant="h5" color="light">
              ZinCash is a safe and user-friendly application developed by Zavron
              Finance Private Limited for quick disbursal of the loan amount with
              minimal documentation. Our mobile loan app is a solution to
              enhance customer accessibility, convenience and ensures customer
              satisfaction.
           </Text>

            <Cluster space="var(--s1)" justify="center">
              <Text variant="h4" style={{ color: '#666666', fontWeight: 'bold' }}>

                “Moving money made quick, efficient & safer”
             </Text>
            </Cluster>

          </Stack>
        </Stack>
        <Box>
          <Frame numerator={28} denominator={21}>
            <img
              src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630947443/ZinCash-Quick-and-Easy-Personal-Loan-App-Image-by-Zavron_ygp0sb.png"
            />
          </Frame>
        </Box>
      </Switcher>
    </Center>
  </Box>
)
const Products: FC = props => (
  <Box
    style={{ margin: "-var(--s2)" }}
    css={css`
      padding-top: var(--s4) !important;
      padding-bottom: var(--s4) !important;
      background-image: url("https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_1400/v1597800020/products-background_p1q9pa.png");
    `}
  >
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Stack space="var(--s3)">


        <Grid gap="var(--s0)" min="500px">
          <Box>
            <Switcher max={2} threshold="0px" space="var(--s0)">
              {/* <NextIcon /> */}

              <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
                <Text
                  style={{
                    textTransform: "uppercase",
                    color: "white",
                    marginBottom: 30,
                  }}
                  variant="h2"
                  css={css`
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          `}
                >
                  EASY STEPS
                </Text>
                {/* <Text variant="h3">{"item.title"}</Text> */}
                <EasySteps data={Easy_Steps} />
                <ul
                  style={{ listStyleType: "none" }}
                  css={css`
                          p {
                            margin-bottom: var(--s-2);
                          }
                        `}
                >
                  <li>
                    <Text
                      variant="h4"
                      color="light"
                      style={{ fontFamily: "var(--body-font-family)" }}
                    >

                    </Text>
                  </li>
                </ul>
              </Stack>
            </Switcher>
          </Box>


          <Box>
            <Switcher max={2} threshold="0px" space="var(--s0)">
              <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
                <Frame numerator={5} denominator={6}>
                  <img
                    src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630949751/ZinCash-Urgent-Personal-Loan-App-Image-by-Zavron_ovkpsr.png"
                    alt=""
                    style={{ height: "auto" }}
                  />
                </Frame>
              </Stack>
            </Switcher>
          </Box>


        </Grid>

      </Stack>

    </Center>
  </Box>
)

const Products1: FC = props => (
  <Box
    style={{ margin: "-var(--s0)" }}
    css={css`

    `}>
    <Center gutters="var(--s2)" maxWidth="var(--max-width)">
      <Stack space="var(--s3)">

        <PointsGrid data={data} />
      </Stack>
    </Center>
  </Box>
)

const EasySteps: FC<{ data: Array<{ title: string; content: string[] }> }> = props => {

  return (
    <Stack space="var(--s1)">      
      {props.data.map(item => (
        <Box>
          <Switcher max={2} threshold="0px" space="var(--s0)">
            <NextIcon />
            <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
              <Text variant="h3" style={{ fontFamily: "var(--heading-font-regular)", color: "white", }}>
                {item.title}
              </Text>
              <ul
                style={{ listStyleType: "none" }}
                css={css`
                          p {
                            margin-bottom: var(--s-2);
                          }
                        `}
              >
                {item.content.map(str => (
                  <li>
                    <Text
                      variant="p"
                      // color="light"
                      style={{ fontFamily: "var(--body-font-family)", color: "#98a5c9" }}
                    >
                      {str}
                    </Text>
                  </li>
                ))}
              </ul>
            </Stack>
          </Switcher>
        </Box>
      ))}


      <Text
        variant="h4"
        style={{ fontWeight: "normal", fontFamily: "var(--body-font-family)" }}>
      </Text>
    </Stack>
  )
}


const PointsGrid: FC<{
  data: Array<{ title: string; content: string[] }>
}> = props => {
  return (

    <Grid gap="var(--s4)" min="500px">
      {props.data.map(item => (
        <Box>
          <Switcher max={2} threshold="0px" space="var(--s0)">
            <NextIcon />
            <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
              <Text variant="h4">{item.title}</Text>
              <ul
                style={{ listStyleType: "none" }}
                css={css`
                          p {
                            margin-bottom: var(--s-2);
                          }
                        `}
              >
                {item.content.map(str => (
                  <li>
                    <Text
                      variant="h4"
                      color="light"
                      style={{ fontFamily: "var(--body-font-family)" }}
                    >
                      {str}
                    </Text>
                  </li>
                ))}
              </ul>
            </Stack>
          </Switcher>
        </Box>
      ))}
    </Grid>
  )
}


const Textdata: FC = props => (

  <Center gutters="var(--s2)" maxWidth="var(--max-width)" intrinsic>
    <Stack space="var(--s3)">
      <Stack space="var(--s-2)">
        <Text
          variant="h5"
          style={{
            fontFamily: 'var(--body-font-family)',
            textAlign: 'center',
          }}>
          {"You can apply for an Instant Personal Loan through ZinCash in a simple and hassle-free method. We are at your fingertips with ZinCash, our mobile app, you can download our app and get loans from INR 10,000 upto INR 2,00,000 lacs quickly."}
        </Text>
      </Stack>
    </Stack>
  </Center>

)


const ProductItem: FC<{
  item: { num: string; title: string; content: string; link: string }
}> = props => {
  const { item } = props
  return (
    <Cluster space="var(--s0)" align="flex-start">
      <Link to={item.link}>
        <Text
          style={{
            color: "var(--secondary-color)",
            fontFamily: "var(--body-font-family)",
          }}
          variant="h2"
        >
          {item.num}
        </Text>
      </Link>
      <Stack space="var(--s0)" style={{ maxWidth: 280 }}>
        <Link to={item.link}>
          <Text
            as="h2"
            style={{
              color: "white",
              fontFamily: "var(--heading-font-regular)",
            }}
            variant="h1"
          >
            {item.title}
          </Text>
        </Link>
        <Text style={{ color: "white" }} variant="p">
          {item.content}
        </Text>
      </Stack>
    </Cluster>
  )
}

const ContactUs: FC = props => {
  const [form] = Form.useForm()
  const { loading, sendData: handleFinish } = useContactUs(() => {
    form.resetFields()
  })
  return (
    <Box padding="var(--s0) 0">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text variant="h1">Connect With Us</Text>
          <Switcher threshold="var(--min-width)" space="var(--s4)" max={2}>
            <Box>
              <Form form={form} onFinish={handleFinish} layout="vertical">
                <Form.Item
                  label="Full Name"
                  name="fullName"
                  rules={[
                    { required: true, message: "Please enter a valid name" },
                  ]}
                >
                  <Input style={{ borderRadius: 5 }} size="large" />
                </Form.Item>
                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid contact number",
                    },
                    {
                      validator: (_, value, callback) => {
                        if (value && MOBILE_NO_REGEX.test(value)) {
                          callback()
                        } else {
                          callback("Please enter a valid contact number")
                        }
                      },
                    },
                  ]}
                >
                  <Input style={{ borderRadius: 5 }} size="large" />
                </Form.Item>
                <Form.Item label="Your Message" name="message">
                  <Input.TextArea rows={8} style={{ borderRadius: 5 }} />
                </Form.Item>
                <Form.Item>
                  <PrimaryButton
                    loading={loading}
                    style={{ width: "100%" }}
                    htmlType="submit"
                  >
                    Send
                  </PrimaryButton>
                </Form.Item>
              </Form>
            </Box>
            <Stack
              css={css`
                  @media screen and (min-width: 900px) {
                    padding-left: var(--s5);
                  }
                `}
              space="var(--s3)"
              justify="center"
            >
              <Stack space="var(--s-1)">
                <Text variant="h4">Address</Text>
                <Stack space="var(--s-5)">
                  <Text color="light" variant="p">
                    125, Zavron Finance Pvt Ltd., Near Memorial Hospital,
                  </Text>
                  <Text color="light" variant="p">
                    Maharajbaugh Road, Sitabuldi,
                  </Text>
                  <Text color="light" variant="p">
                    Nagpur -440012
                  </Text>
                </Stack>
              </Stack>

              <Stack space="var(--s-1)">
                <Text variant="h4">Support</Text>
                <Stack space="var(--s-5)">
                  <a href="tel: +91-8447723591">
                    <Text color="light" variant="p">
                      +91-8447723591
                    </Text>
                  </a>
                  <a href="mailto:info@zavronfinserv.com">
                    <Text color="light" variant="p">
                      info@zavronfinserv.com
                    </Text>
                  </a>
                </Stack>
              </Stack>

              {/* <Stack space="var(--s-1)">
                  <Text variant="h4">Timing</Text>
                  <Stack space="var(--s-5)">
                    <Text color="light" variant="p">
                      9 AM - 9 PM
                    </Text>
                    <Text color="light" variant="p">
                      Monday - Friday
                    </Text>
                  </Stack>
                </Stack> */}
            </Stack>
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}

const WhyUs: FC = props => {
  return (
    <Box id="home-why-us">
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s3)">
          <Text
            style={{ textTransform: "uppercase" }}
            variant="h3"
            css={css`
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            `}
          >
            Features & Benefits
          </Text>
          <Switcher max={4} threshold="var(--min-width)" space="var(--s1)">
            {Features.map(item => (
              <Stack space="var(--s1)" align="center">
                {item.icon}
                <Stack space="var(--s-2)" align="center">
                  <Text variant="h4">{item.title}</Text>
                  <Text
                    color="light"
                    style={{ textAlign: "center" }}
                    variant="p"
                  >
                    {item.content}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}


const BeforApply: FC = props => {
  return (
    <Box>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
          <Box>
            <Frame numerator={29} denominator={20}>
              <img src={"https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1630944503/ZinCash-Download-Personal-Loan-Mobile-App-for-emergency_e3hw2m.png"} alt={"product.name"} />

            </Frame>
          </Box>
          <Stack style={{ flexGrow: 2 }} space="var(--s4)">
            <Stack space="var(--s-4)" style={{ paddingTop: 'var(--s2)', paddingLeft: 'var(--s3)' }}>
              <Text as="h1" variant="h2">
                {"Before you apply a loan"}
              </Text>
              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  whiteSpace: 'pre-line',
                }}
                color="light">
                {"We recommend you to keep following documents handy "}
                {' '}

              </Text>

              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  whiteSpace: 'pre-line',
                  marginBottom: 20
                }}
                color="light">
                {"before you apply for an Instant Personal loan through ZinCash"}
                {' '}

              </Text>


              <Stack space="var(--s0)" style={{ paddingLeft: 'var(--s3)' }}>


                {befor_Apply.map(item => (
                  <Box>
                    <Switcher max={2} threshold="0px" space="var(--s0)">
                      <NextIcon />
                      <Stack space="var(--s0)" style={{ flexGrow: 15 }}>
                        <Text variant="h4" color="light" style={{ fontFamily: "var(--heading-font-regular)" }}>
                          {item.title}
                        </Text>
                        <ul
                          style={{ listStyleType: "none" }}
                          css={css`
                          p {
                            margin-bottom: var(--s-2);
                          }
                        `}
                        >

                        </ul>
                      </Stack>
                    </Switcher>
                  </Box>
                ))}


                <Text
                  variant="h4"
                  style={{ fontWeight: "normal", fontFamily: "var(--body-font-family)" }}
                >

                </Text>
              </Stack>



            </Stack>

            <Cluster space="var(--s1)" justify="center">

            <Link to="https://play.google.com/store/apps/details?id=com.zavrofinserv.customer"   target="_blank" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <img
                  width="90"
                  //  height="20"
                  src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934494/android_ma9ttf.png"
                  alt="logo"
                  css={css`
                        @media screen and (max-width: 200px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                />
              </Link>
              
            <Link to="https://apps.apple.com/in/app/zincash/id1581920928" target="_blank" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <img
                  width="90"
                  //  height="20"
                  src="https://res.cloudinary.com/dhoiqmk4x/image/upload/v1630934856/apple_jrlute.png"
                  alt="logo"
                  css={css`
                        @media screen and (max-width: 200px) {
                          margin-bottom: 0 !important;
                        }
                      `}
                />
              </Link>
            </Cluster>

          </Stack>


        </Switcher>
      </Center>
    </Box>
  )
}
