import React, { FC } from "react"
import { StickyContainer } from "react-sticky"
import { Footer } from "./Footer"
import { Stack } from "../common"
import { ZincashHeader } from "./ZincashHeader"

const MobileLayout: FC = ({ children }) => {
  return (
    <StickyContainer>
      {/* <Header /> */}
      <ZincashHeader redirectToZinCash={true} />
      <Stack style={{ paddingTop: "var(--s1)" }} space="var(--s3)">
        {children}
        <Footer />
      </Stack>
    </StickyContainer>
  )
}

export { MobileLayout }
